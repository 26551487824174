import React from 'react';

const ArrowIcon = ({ width = 16, height = 16, fill = 'currentColor', className = '' }) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill={fill}
      xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M4 16 L16 4 M16 4 L10 4 M16 4 L16 10" stroke={fill} strokeWidth="1.5" fill="none" />
    </svg>
  );
};


export default ArrowIcon;
