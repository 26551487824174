import React from 'react';
import ArrowIcon from './ArrowIcon.tsx';

const LandingPage: React.FC = () => {
  return (
    <div className="landing-page">
      <p className="logo">Nonfiction.</p>
      <p>
        <span style={{ display: 'inline-block', height: '2.5em', verticalAlign: 'bottom' }}>
            <img src={process.env.PUBLIC_URL + '/illuminated_w.png'} alt="W" style={{ height: '100%' }} />
        </span>
        &#8202;e’re a team of optimistic and thoughtful builders. Our mission is to use artificial intelligence as a tool to augment human intelligence, output, and ability.
      </p>
      <p><a href="https://nonfictiontech.substack.com/">Subscribe to our newsletter</a> <ArrowIcon /> </p>
      <p className="footer">We are <a href="https://emirakdere.com">Emir Akdere</a>,<ArrowIcon /> <a href="https://www.echenran.xyz/">Emmett Chen-Ran</a>,<ArrowIcon /> and <a href="http://eddiejiao.com">Eddie Jiao</a>&thinsp;<ArrowIcon /></p>

      <p className="secret"> Special thanks to Jason Yuan for helping workshop the company name.</p>
    </div>
  );
};

export default LandingPage;
